<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary mb-2">
        Mobile Please! 🚧
      </h2>
      <p class="text-sm">
        Please access the Smart City App on your phone. <br />
        You can quickly access it with the QR code below.
      </p>

      <div class="misc-character d-flex align-center justify-center">
        <div
          class="imgHolder"
        >
          <v-img
            max-width="650"
            src="@/assets/images/3d-characters/under-maintenance.png"
          ></v-img>
        </div>
        <div
          class="qrHolder"
        >
          <vue-qr
            :text="url"
            :size="180"
          ></vue-qr>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr'

export default {
  components: { VueQr },
  setup() {
    const url = window.location.origin

    return { url }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
.imgHolder{
  margin-left: -20%;
}
.qrHolder {
  position: relative;
  width: auto;
  margin-left: -8%;
  img {
    display: block;
    border: .0556rem solid #0288D1;
  }
}
</style>
